import { Avatar, Box, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { useFormattedDate } from '../../base/hooks/useFormattedDate';

export const PublisherCard = () => {
    const date = useFormattedDate( new Date() );
    return <Box>
        <Flex justifyContent="space-between" alignItems="center">
            <Heading as="h3" size="md">Game Publisher</Heading>
            <Avatar name="Kola Tioluwani" src="https://bit.ly/tioluwani-kolawole"/>
        </Flex>

        <Box>
            { date }
        </Box>
    </Box>
}

export default PublisherCard;
