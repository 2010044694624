import { extendTheme, theme as baseTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { PRIMARY } from './design-tokens';

// @see https://chakra-ui.com/docs/theming/theme
export const theme = extendTheme( {
    initialColorMode: "dark",

    styles: {
        global: {
            body: {
                bg: "gray.800",
                color: "gray.50",
            },
        },
    },
    colors: {
        brand: baseTheme.colors.yellow,
    },
    components: {
        MenuList: {
            baseStyle: {
                backgroundColor: 'blue'
            },
        },
    },
}, withDefaultColorScheme( { colorScheme: PRIMARY } ) )
