import { Box, Button, Flex, Heading, Image, Tag } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PRIMARY } from '../../../config/design-tokens';
import { useFormattedPrice } from '../../base/hooks/useFormattedPrice';

export type TeaserGameCardProps = {
    id: number;
    visualUrl: string;
    name: string;
    description: string;
    price: number;
    currency: string;
};

const MAX_DESCRIPTION_LENGTH = 80;

export const TeaserGameCard = ({ id, visualUrl, name, description, currency, price }: TeaserGameCardProps) => {
    const formattedPrice = useFormattedPrice( price, currency );
    const cuttedText = useMemo( () => {
        if (description.length <= MAX_DESCRIPTION_LENGTH) {
            return description;
        }
        const text = description.substr( 0, MAX_DESCRIPTION_LENGTH );
        return `${ text }…`;
    }, [description] );

    return (
        <Box borderRadius="lg" overflow="hidden">
            <Flex direction="column" overflow="hidden">
                <Image
                    src={ visualUrl }
                    height="300px"
                    objectFit="cover"
                />
                <Box p={ 4 } backgroundColor="gray.700">
                    <Flex justifyContent="space-between">
                        <Heading size="md">
                            { name }
                        </Heading>
                    </Flex>

                    <Box mt={ 4 } mb={ 4 }>
                        { cuttedText }
                    </Box>
                    <Flex justifyContent="space-between">
                        <Tag colorScheme={ PRIMARY }>{ formattedPrice }</Tag>

                        <Button variant="link" as={ Link } to={ `/store/games/${ id }` }>
                            More
                        </Button>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
}

export default TeaserGameCard;
