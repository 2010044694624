import { Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

export const FullScreenLoader = () => {
    return (<Flex width="100%" height="100%" justifyContent="center" alignItems="center">
        <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            key="loader"
        />
    </Flex>);
}
