import { Box, Flex } from '@chakra-ui/react';
import React, { PropsWithChildren, useMemo } from 'react';
import { IDX_HEADER } from '../../../config/zIndex';
import { HeaderBar } from '../components/HeaderBar';

export enum CONTENT_SIZES {
    NARROW = 'narrow',
    WIDE = 'wide',
}

type AppLayoutProps = PropsWithChildren<{
    contentSize?: CONTENT_SIZES
}>;

const SIZE_MAP = {
    [CONTENT_SIZES.NARROW]: 960,
    [CONTENT_SIZES.WIDE]: 1440,
}

export const AppLayout = ({ children, contentSize }: AppLayoutProps) => {
    const size = useMemo( () => {
        if (!contentSize) {
            return SIZE_MAP[CONTENT_SIZES.WIDE];
        }
        return SIZE_MAP[contentSize];
    }, [contentSize] );

    return (
        <Flex direction="column" alignItems="center" justifyContent="center">
            <Flex w="100%"
                  justifyContent="center"
                  position="sticky"
                  top={ 0 }
                  background="gray.800"
                  zIndex={ IDX_HEADER }>
                <Box maxW={ 1440 } w="100%" p={ 2 } height="120px">
                    <HeaderBar/>
                </Box>
            </Flex>
            <Box maxW={ size } w="100%" mt={ 4 } p={ 2 } margin="0 auto">
                { children }
            </Box>
        </Flex>
    );
}

export default AppLayout;
