import { Box, Flex } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import { HeaderBar } from '../../base/components/HeaderBar';

type AppLayoutProps = PropsWithChildren<{
    headerImage: string;
}>;

export const GameDetailLayout = ({ children, headerImage }: AppLayoutProps) => {
    const bg = `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),
    url('${ headerImage }');`;

    return (
        <Flex direction="column" alignItems="center">
            <Flex w="100%" justifyContent="center" backgroundImage={ bg } backgroundSize="cover" height="45vh">
                <Box maxW={ 1440 } w="100%" p={ 2 } height="120px">
                    <HeaderBar/>
                </Box>
            </Flex>
            <Box maxW={ 1440 } w="100%" mt={ 4 } p={ 2 }>
                { children }
            </Box>
        </Flex>
    );
}

