import React, { ComponentType, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FullScreenLoader } from '../../base/components/FullScreenLoader';
import { fetchGame, GameModel } from '../api/games';

export type WithCurrentGameDetailsRouteParams = {
    id: string;
}

export type WithCurrentGameDetailsProps = {
    game: GameModel;
}

export function withCurrentGameDetails<P = {}>(Component: ComponentType<P>): React.FC<P> {
    return function withCurrentGameDetailsContainer(props) {
        const [game, setGame] = useState<GameModel | null>();
        const [isLoading, setLoading] = useState( true );
        const { id } = useParams<WithCurrentGameDetailsRouteParams>();

        useEffect( () => {
            setLoading( true )
            fetchGame( parseInt( id ) )
                .then( (game) => setGame( game ) )
                .finally( () => {
                    setLoading( false );
                } );
        }, [] );

        if (isLoading) {
            return <FullScreenLoader/>;
        }

        return <Component { ...props } game={ game }/>;
    }
}
