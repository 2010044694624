import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { FullScreenLoader } from '../../base/components/FullScreenLoader';
import { DeviceInfo, getDeviceInfo } from '../api/device';

export type WithDeviceInfoProps = {
    deviceInfo: DeviceInfo | null;
    refreshDeviceInfo: () => void
}

export function withDeviceInfo<P = {}>(Component: ComponentType<P>): React.FC<P> {
    return function withDeviceInfoContainer(props) {
        const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | null>( null )
        const [isLoading, setLoading] = useState( true );

        const fetchDeviceInfo = useCallback( () => {
            setLoading( true );
            getDeviceInfo()
                .then( (info) => setDeviceInfo( info ) )
                .finally( () => setLoading( false ) );
        }, [] );

        useEffect( () => {
            fetchDeviceInfo();
        }, [] );

        if (isLoading && !deviceInfo) {
            return <FullScreenLoader/>
        }

        return <Component { ...props } deviceInfo={ deviceInfo } refreshDeviceInfo={ fetchDeviceInfo }/>;
    }
}
