import { useMemo } from 'react';

export const useFormattedDateTime = (date: Date | undefined, style: "medium" | "full" | "long" | "short" | undefined = 'medium', timeStyle: "medium" | "full" | "long" | "short" | undefined = 'medium') => {
    return useMemo( () => {
        if (!date) {
            return null;
        }
        return new Intl.DateTimeFormat( 'en-GB', { dateStyle: style, timeStyle } ).format( date )
    }, [date] );
}
