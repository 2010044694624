import { Box, Button, Flex, FormControl, FormLabel, Image, Input } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { PRIMARY } from '../../../../config/design-tokens';

export type LoginFormProps = {
    onLogin: (username: string, password: string) => void,
    isLoading?: boolean
};

export default function LoginForm({ onLogin, isLoading }: LoginFormProps) {
    const [userName, setUserName] = useState( '' );
    const [password, setPassword] = useState( '' );

    const onLoginClicked = useCallback( () => {
        onLogin( userName, password )
    }, [userName, password] );

    return (<Box maxW={ 300 }>
        <Flex justifyContent="center">
            <Image
                boxSize="100px"
                objectFit="cover"
                src="/logo_white.svg"
                alt="Mondstein Logo"
            />
        </Flex>

        <FormControl mt={ 4 }>
            <FormLabel>E-Mail</FormLabel>
            <Input colorScheme={ PRIMARY } value={ userName } onChange={ (e) => setUserName( e.target.value ) }
                   type="email"
                   placeholder="me@example.com" size="lg"/>
        </FormControl>

        <FormControl mt={ 4 }>
            <FormLabel>Password</FormLabel>
            <Input value={ password } onChange={ (e) => setPassword( e.target.value ) } type="password"
                   placeholder="Password" size="lg"/>
        </FormControl>

        <Flex justifyContent="flex-end" mt={ 4 }>
            <Button variant="link" mr={ 2 }>Sign Up</Button>
            <Button isLoading={ isLoading } onClick={ onLoginClicked } colorScheme={ PRIMARY }>
                Log In
            </Button>
        </Flex>
    </Box>);
}
