import Cookies from 'js-cookie';
import React, { ComponentType, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { COOKIE_NAME } from '../../../config/constances';
import { FullScreenLoader } from '../../base/components/FullScreenLoader';
import * as authApi from '../api/auth';
import { isAuthenticatedState } from '../state/is-authenticated';
import { isCookieVerifiedState } from '../state/is-cookie-verified-state';

export type WithAuthCheckProps = {
    isAuthenticated: boolean;
}

export function withAuthCheck<P = {}>(Component: ComponentType<P>): React.FC<P> {
    return function withAuthCheckContainer(props) {
        const [isAuthenticated, setAuth] = useRecoilState( isAuthenticatedState );
        const [isCookieVerified, setCookieVerified] = useRecoilState( isCookieVerifiedState );
        const [isLoading, setLoading] = useState( false );

        const authCheck = () => {
            setLoading( true );
            authApi.verifyAuth()
                .then( (isValid) => {
                    setAuth( isValid );
                    Cookies.set( COOKIE_NAME, '' );
                } )
                .catch( () => {
                    setAuth( false );
                } )
                .finally( () => {
                    setCookieVerified( true );
                    setLoading( false );
                } )
        };

        useEffect( () => {
            if (!isAuthenticated || !isCookieVerified) {
                authCheck();
            }
        }, [] )

        if (isLoading) {
            return <FullScreenLoader/>;
        }

        return <Component { ...props } isAuthenticated={ isAuthenticated }/>;
    }
}
