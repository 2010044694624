import { CustomRoute } from '../../router/routes';
import DetailPage from './pages/DetailPage';
import OverviewPage from './pages/OverviewPage';

export const gamesRoutes: CustomRoute[] = [
    {
        path: '/store/games/:id',
        exact: true,
        component: DetailPage,
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: '/store/games',
        exact: true,
        component: OverviewPage,
        meta: {
            requiresAuth: true,
        },
    },
];
