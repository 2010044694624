import { getAxios } from '../../../utils/get-axios';

export type GameModel = {
    id: number;
    name: string;
    installs: number;
    genre: string;
    price: number;
    currency: string;
    primaryVisual: string;
    description: string;
    created_at: Date;
    updated_at: Date;
    is_featured: boolean;
}

export const fetchGames = (): Promise<GameModel[]> => {
    return getAxios()
        .get<GameModel[]>( '/store/games' )
        .then( ({ data }) => data );
}

export const fetchGame = (gameId: number): Promise<GameModel> => {
    return getAxios()
        .get( `/store/games/${ gameId }` )
        .then( ({ data }) => data )
}

export const fetchFeaturedGames = (): Promise<GameModel[]> => {
    return getAxios()
        .get<GameModel[]>( '/store/games/featured' )
        .then( ({ data }) => data );
}
