import { Box, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import React from 'react';
import { useFormattedDate } from '../../base/hooks/useFormattedDate';
import { useFormattedNumber } from '../../base/hooks/useFormattedNumber';

export const MetaDataCard = () => {
    const date = useFormattedDate( new Date() );
    const installs = useFormattedNumber( 10_000 );
    return <Box>
        <Stat>
            <StatLabel>Published</StatLabel>
            <StatNumber> { date }</StatNumber>
        </Stat>

        <Stat mt={ 4 }>
            <StatLabel>Installs</StatLabel>
            <StatNumber> { installs } </StatNumber>
        </Stat>
        <Stat mt={ 4 }>
            <StatLabel>Genre</StatLabel>
            <StatNumber> Unknown </StatNumber>
        </Stat>
    </Box>
}

export default MetaDataCard;
