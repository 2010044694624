import { getAxios } from '../../../utils/get-axios';

export const connectToUser = (deviceId: string): Promise<boolean> => {
    return getAxios().patch( '/devices/connect', { device: deviceId } )
        .then( () => {return true;} );
}

export type DeviceInfo = {
    currentLocalIp: string;
    currentVersion: string;
    lastSeen: Date;
    deviceUuid: string;
}

export const getDeviceInfo = (): Promise<DeviceInfo> => {
    return getAxios().get( '/devices/me' )
        .then( ({ data }) => ({ ...data, lastSeen: data?.lastSeen ? new Date( data.lastSeen ) : null }) );
}

export const disconnectFromUser = () => {
    return getAxios()
        .delete( '/devices/me' )
        .then( () => {} )
}
