import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, useToast, VStack } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { PRIMARY } from '../../../../config/design-tokens';
import { Section } from '../../../base/components/Section';
import { changeUserPassword } from '../../api/user';

export type ChangePasswordProps = {
    onPasswordChanged?: () => void;
    isForceOpen?: boolean;
};

export const ChangePassword = ({ onPasswordChanged, isForceOpen }: ChangePasswordProps) => {
    const [currentPw, setCurrentPw] = useState( '' );
    const [newPw, setNewPw] = useState( '' );
    const [repeatNewPw, setRepeatNewPw] = useState( '' );
    const [isLoading, setLoading] = useState( false );
    const toast = useToast();

    const doNewPwMatch = useMemo( () => {
        return newPw === repeatNewPw && newPw.length >= 8;
    }, [newPw, repeatNewPw] );

    const isSubmittable = useMemo( () => {
        return doNewPwMatch && !!currentPw;
    }, [doNewPwMatch, currentPw] );

    const onSubmitClicked = useCallback( () => {
        setLoading( true );
        changeUserPassword( currentPw, newPw )
            .then( () => {
                toast( {
                    title: 'Password changed',
                    description: 'Password successfully changed. Please log in again.',
                    status: 'success',
                } );

                onPasswordChanged?.();
            } )
            .catch( () => {
                toast( {
                    title: 'Error',
                    description: 'Password could not be changed due to an error.',
                    status: 'error',
                } )
            } )
            .finally( () => {
                setLoading( false )
            } )
    }, [newPw] )

    return (
        <Section title="Password" isCollapsible={ true } isForceOpen={ isForceOpen }>
            <VStack
                mt={ 10 }
                spacing={ 8 }
                align="stretch">
                <FormControl>
                    <FormLabel>Current Password</FormLabel>
                    <Input colorScheme={ PRIMARY }
                           value={ currentPw }
                           onChange={ (e) => setCurrentPw( e.target.value ) }
                           type="password"
                           size="lg"/>
                </FormControl>

                <FormControl>
                    <FormLabel>New Password</FormLabel>
                    <Input colorScheme={ PRIMARY }
                           value={ newPw }
                           onChange={ (e) => setNewPw( e.target.value ) }
                           type="password"
                           size="lg"/>
                </FormControl>

                <FormControl isInvalid={ !doNewPwMatch && newPw.length > 0 }>
                    <FormLabel>Repeat new Password</FormLabel>
                    <Input colorScheme={ PRIMARY }
                           value={ repeatNewPw }
                           onChange={ (e) => setRepeatNewPw( e.target.value ) }
                           type="password"
                           size="lg"/>
                    { !doNewPwMatch && <FormErrorMessage>Passwords do not match!</FormErrorMessage> }
                </FormControl>

                <Flex justifyContent="flex-end">
                    <Button isLoading={ isLoading } disabled={ !isSubmittable } onClick={ onSubmitClicked }>
                        Change
                    </Button>
                </Flex>
            </VStack>
        </Section>
    );
}
