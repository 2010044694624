import { Button, Flex, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react';
import { compose } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PRIMARY } from '../../../../config/design-tokens';
import { Section } from '../../../base/components/Section';
import { withUserInfo, WithUserInfoProps } from '../../../user/hoc/with-user-info';
import { connectToUser } from '../../api/device';
import { withDeviceInfo, WithDeviceInfoProps } from '../../hoc/withDeviceInfo';

export type ConnectedDeviceFormProps = WithDeviceInfoProps & WithUserInfoProps & {
    hasConnectedDevice: boolean;
    onDeviceConnected?: () => void;
    onDeviceDisconnected?: () => void;
};

export const ConnectDeviceForm = ({
                                      hasConnectedDevice,
                                      onDeviceConnected,
                                      onDeviceDisconnected,
                                      refreshDeviceInfo,
                                      refreshUserInfo,
                                  }: ConnectedDeviceFormProps) => {
    const [deviceId, setDeviceId] = useState( '' );
    const [isLoading, setLoading] = useState( false );
    const toast = useToast();
    const [t] = useTranslation( 'device' );

    const onConnect = useCallback( () => {
        setLoading( true );
        connectToUser( deviceId )
            .then( () => {
                toast( {
                    title: t( 'device.linked.success.title' ),
                    description: t( 'device.linked.success.title' ),
                    status: 'success',
                } );
                onDeviceConnected?.();
                refreshDeviceInfo();
                refreshUserInfo();
            } )
            .catch( () => {
                toast( {
                    title: t( 'device.linked.failed.title' ),
                    description: t( 'device.linked.failed.description' ),
                    status: 'error',
                } );
            } )
            .finally( () => {
                setLoading( false );
            } );
    }, [deviceId] );

    return (
        <Section title={ t( 'device.link.title' ) }>
            <FormControl>
                <FormLabel>{ t( 'device.link.device' ) }</FormLabel>
                <Input colorScheme={ PRIMARY }
                       value={ deviceId }
                       onChange={ (e) => setDeviceId( e.target.value ) }
                       size="lg"/>
            </FormControl>

            <Flex justifyContent={ 'flex-end' } mt={ 6 }>
                <Button onClick={ onConnect }>
                    { t( 'device.link.cta' ) }
                </Button>
            </Flex>
        </Section>
    );
}

export default compose( withUserInfo, withDeviceInfo )( ConnectDeviceForm );
