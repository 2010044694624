import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { EmptyState } from '../components/EmptyState/EmptyState';
import AppLayout from '../layouts/AppLayout';
import { breadcrumbState } from '../state/breadcrumbs';

export default function NotFoundPage() {
    const setBreadcrumbs = useSetRecoilState( breadcrumbState );

    useEffect( () => {
        setBreadcrumbs( [] );
    }, [] );

    return (
        <AppLayout>
            <EmptyState title={ 'Ooopps... Page not found' } link={ '/' } linkLabel={ 'Back to Home' }
                        illustration={ 'undraw_not_found_60pq' }/>
        </AppLayout>
    );
}
