import { Avatar, Flex, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import React from 'react';
import { Section } from '../../../base/components/Section';
import { UserInfoData } from '../../api/user';

export type UserProfileCardProps = {
    userInfo: UserInfoData;
}

export const UserProfileCard = ({ userInfo }: UserProfileCardProps) => {
    return <Section title={ 'Profile' }>
        <Flex>
            <Stat>
                <StatLabel>Username</StatLabel>
                <StatNumber>{ userInfo.username }</StatNumber>
            </Stat>

            <Avatar name={ userInfo.username }/>
        </Flex>
    </Section>
}
