import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, Grid, Image } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import UserMenu from '../../user/components/UserMenu/UserMenu';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';

export const HeaderBar = () => {
    const [breadcrumbs] = useBreadcrumbs();

    return <Grid alignItems="center" justifyContent="space-between" w="100%" height="100%"
                 gridTemplateColumns="1fr 1fr">
        <Flex alignItems="center">
            <Image src="/logo_white.svg"
                   boxSize={ 10 }
                   objectFit="cover"
                   alt="Mondstein Logo"/>

            <Breadcrumb ml={ 2 }>
                { breadcrumbs.map( item => (
                    <BreadcrumbItem key={ item.url }>
                        <BreadcrumbLink as={ Link } to={ item.url }>
                            { item.text }
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                ) ) }
            </Breadcrumb>
        </Flex>

        <Flex justifyContent="flex-end">
            <UserMenu/>
        </Flex>
    </Grid>
}
