import { getAxios } from '../../../utils/get-axios';

export type UserInfoData = {
    username: string;
    hasConnectedDevice: boolean;
}

export const getUserInfo = (): Promise<UserInfoData> => {
    return getAxios()
        .get( '/users/me' )
        .then( ({ data }) => data );
}

export const changeUserPassword = (oldPassword: string, newPassword: string): Promise<void> => {
    return getAxios()
        .patch( `/users/me/password`, { oldPassword, newPassword } )
        .then( () => {} )
}
