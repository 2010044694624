import { Button, Flex } from '@chakra-ui/react';
import { compose } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';
import { EmptyState } from '../../base/components/EmptyState/EmptyState';
import { withAppLayout } from '../../base/hoc/withAppLayout';
import { useBreadcrumbs } from '../../base/hooks/useBreadcrumbs';
import GameGrid from '../components/GameGrid';
import { withGames, WithGamesProps } from '../hoc/with-games';

type Props = WithGamesProps;

export function OverviewPage({ games, isLoading }: Props) {
    useBreadcrumbs( [
        { text: 'Store', url: '/' },
        { text: 'Games', url: '/store/games' },
    ] );
    if (!isLoading && games.length === 0) {
        return <EmptyState title={ 'No Games found' } link={ '/' } linkLabel={ 'Back to Home' }
                           illustration={ 'undraw_empty_xct9' }/>
    }

    return <>
        <Flex justifyContent="center" mb={10}>
            <Button variant="link" as={ Link } to="/store/games" mr={ 2 }>
                Games
            </Button>
            <Button variant="link" as={ Link } to="/store/products">
                Products
            </Button>
        </Flex>

        <GameGrid games={ games }/>
    </> ;
}

export default compose( withAppLayout, withGames )( OverviewPage );
