import React, { ComponentType, useEffect, useState } from 'react';
import { BackendInfoData, getBackendInfo } from '../api/backend-info';
import { FullScreenLoader } from '../components/FullScreenLoader';

export type WithBackendInfoProps = {
    backendInfo: BackendInfoData
}

export function withBackendInfo<P = {}>(Component: ComponentType<P>): React.FC<P> {
    return function withBackendInfoContainer(props) {
        const [isLoading, setLoading] = useState( true );
        const [backendInfo, setBackendInfo] = useState<BackendInfoData | null>( null );

        useEffect( () => {
            setLoading( true );
            getBackendInfo()
                .then( (info) => {
                    setBackendInfo( info )
                } )
                .catch( () => {
                    setBackendInfo( { environment: '?', version: '?' } );
                } )
                .finally( () => {
                    setLoading( false );
                } )

        }, [] );

        if (isLoading) {
            return <FullScreenLoader/>
        }

        return <Component { ...props } backendInfo={ backendInfo }/>;
    }
}
