import { Box, Button, Flex, Grid, Heading, Tag } from '@chakra-ui/react';
import { compose } from 'lodash/fp';
import React from 'react';
import { useBreadcrumbs } from '../../base/hooks/useBreadcrumbs';
import { useFormattedPrice } from '../../base/hooks/useFormattedPrice';
import MetaDataCard from '../components/MetaDataCard';
import PublisherCard from '../components/PublisherCard';
import { withCurrentGameDetails, WithCurrentGameDetailsProps } from '../hoc/with-current-game-details';
import { GameDetailLayout } from '../layouts/GameDetailLayout';

type DetailPageProps = WithCurrentGameDetailsProps & {};

export function DetailPage({ game }: DetailPageProps) {
    const price = useFormattedPrice( game.price, game.currency );

    useBreadcrumbs( [
        { text: 'Store', url: '/' },
        { text: 'Games', url: '/store/games' },
        { text: game.name, url: `/store/games/${ game.id }` },
    ], [] );

    return (<GameDetailLayout headerImage={ game.primaryVisual }>
        <Flex alignItems="center" mb={ 4 }>
            <Heading size="2xl">
                { game.name }
            </Heading>
            { game.is_featured && <Tag ml={ 4 }>Featured</Tag> }
        </Flex>

        <Grid templateColumns="8fr 4fr" gap={ 4 }>
            <Box>
                <Box p={ 4 } background="gray.600">
                    { game.description }
                </Box>
            </Box>

            <Box>
                <Box p={ 4 } borderWidth="1px">
                    <PublisherCard/>
                </Box>
                <Box p={ 4 } borderWidth="1px" mt={ 4 } mb={ 4 }>
                    <MetaDataCard/>
                </Box>
                <Button mt={ 4 }>
                    Get it for { price }
                </Button>
            </Box>
        </Grid>
    </GameDetailLayout>);
}

export default compose( withCurrentGameDetails )( DetailPage );
