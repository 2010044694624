import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { BreadcrumbItem, breadcrumbState } from '../state/breadcrumbs';

export const useBreadcrumbs = (path?: BreadcrumbItem[], deps: any[] = []): [BreadcrumbItem[], (p: BreadcrumbItem[]) => void] => {
    const [breadcrumbs, setBreadcrumbs] = useRecoilState( breadcrumbState );

    useEffect( () => {
        if (path) {
            setBreadcrumbs( path );
        }
    }, deps )

    return [breadcrumbs, setBreadcrumbs];
}
