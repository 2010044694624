import Cookies from 'js-cookie';
import { COOKIE_NAME } from '../../../config/constances';
import { getAxios } from '../../../utils/get-axios';

export const doLogin = (username: string, password: string): Promise<boolean> => {
    return getAxios()
        .post( '/auth/login', { username, password } )
        .then( () => {
            return true;
        } )
}

export const doLogOut = (): Promise<void> => {
    return getAxios()
        .post( '/auth/logout' )
        .then( () => {
            Cookies.remove( COOKIE_NAME );
        } );
}

export const verifyAuth = (): Promise<boolean> => {
    return getAxios()
        .get( '/auth/check' )
        .then( ({ data }) => data.verified );
}
