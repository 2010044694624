import { Grid } from '@chakra-ui/react';
import React from 'react';
import { GameModel } from '../api/games';
import GameCard from './GameCard';

type GameGridProps = {
    games: GameModel[];
}

export default function GameGrid({ games }: GameGridProps) {
    return (<Grid templateColumns="repeat(5, 1fr)" gap={ 5 }>
        { games.map( (game) => <GameCard key={ game.id }
                                         id={ game.id }
                                         visualUrl={ game.primaryVisual }
                                         is_featured={ game.is_featured }
                                         name={ game.name }
                                         description={ game.description } price={ game.price }
                                         currency={ game.currency }/> ) }
    </Grid>);
}
