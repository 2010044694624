import { Button, Flex, Grid, Stat, StatLabel, StatNumber, useToast } from '@chakra-ui/react';
import { compose } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from '../../../base/components/Section';
import { useFormattedDateTime } from '../../../base/hooks/useFormattedDateTime';
import { disconnectFromUser } from '../../api/device';
import { withDeviceInfo, WithDeviceInfoProps } from '../../hoc/withDeviceInfo';

export type DeviceInfoProps = WithDeviceInfoProps & {
    onDisconnected: () => void;
    isForceOpen?: boolean;
};

export const DeviceInfo = ({ deviceInfo, onDisconnected, isForceOpen }: DeviceInfoProps) => {
    const [isUnlinking, setIsUnlinking] = useState( false );
    const formattedLastSeen = useFormattedDateTime( deviceInfo?.lastSeen, 'long' );
    const toast = useToast();
    const [t] = useTranslation( 'device' );

    const onDisconnectClicked = useCallback( () => {
        setIsUnlinking( true );

        disconnectFromUser()
            .then( () => {
                toast( {
                    title: t( 'device.unlinked.success.title' ),
                    description: t( 'device.unlinked.success.description' ),
                    status: 'success',
                } );
                onDisconnected();
            } )
            .catch( () => {
                toast( {
                    title: t( 'device.unlinked.success.title' ),
                    description: t( 'device.unlinked.success.description' ),
                    status: 'error',
                } );
            } )
            .finally( () => {
                setIsUnlinking( false );
            } );
    }, [onDisconnected] );

    return (<Section title={ t( 'device.section.title' ) } isCollapsible={ true } isForceOpen={ isForceOpen }>
        <Grid gap={ 4 }>
            <Stat>
                <StatLabel>{ t( 'device.info.firmware_version_label' ) }</StatLabel>
                <StatNumber>v1.0.1</StatNumber>
            </Stat>
            <Stat>
                <StatLabel>{ t( 'device.info.last_connected_label' ) }</StatLabel>
                <StatNumber> { formattedLastSeen } </StatNumber>
            </Stat>
            <Stat>
                <StatLabel>{ t( 'device.info.current_ip_label' ) }</StatLabel>
                <StatNumber>{ deviceInfo?.currentLocalIp }</StatNumber>
            </Stat>
            <Flex justifyContent={ 'flex-end' }>
                <Button isLoading={ isUnlinking } colorScheme={ 'red' } onClick={ onDisconnectClicked }>
                    { t( 'device.unlink.cta.label' ) }
                </Button>
            </Flex>
        </Grid>
    </Section>);
}

export default compose( withDeviceInfo )( DeviceInfo );
