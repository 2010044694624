import { compose } from 'lodash/fp';
import React from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { withAuthCheck, WithAuthCheckProps } from '../../auth/hoc/with-auth-check';

export type PrivateRouteProps = WithAuthCheckProps & RouteProps;

export const PrivateRoute = ({ component, isAuthenticated, ...rest }: PrivateRouteProps) => {
    if (!isAuthenticated) {
        return <Redirect to="/auth/login"/>;
    }

    return <Route { ...rest } component={ component }/>;
}

export default compose( withAuthCheck )( PrivateRoute );
