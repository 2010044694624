import { RouteProps } from 'react-router';
import { authRoutes } from '../modules/auth/routes';
import { dashboardRoutes } from '../modules/dashboard/routes';
import { deviceRoutes } from '../modules/device/routes';
import { gamesRoutes } from '../modules/games/routes';
import { userRoutes } from '../modules/user/routes';

export type CustomRoute = RouteProps & {
    meta?: { requiresAuth: boolean };
}

export const routes: CustomRoute[] = [
    ...authRoutes,
    ...dashboardRoutes,
    ...gamesRoutes,
    ...userRoutes,
    ...deviceRoutes,
];
