import { getAxios } from '../../../utils/get-axios';

export type BackendInfoData = {
    environment: string;
    version: string;
}

export const getBackendInfo = (): Promise<BackendInfoData> => {
    return getAxios()
        .get( '/info' )
        .then( ({ data }) => data );
}
