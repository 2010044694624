import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { fetchGames, GameModel } from '../api/games';

export type WithGamesProps = {
    games: GameModel[];
    isLoading: boolean;
}

export function withGames<P = {}>(Component: ComponentType<P>): React.FC<P> {
    return function withGamesContainer(props) {
        const [games, setGames] = useState<GameModel[]>( [] );
        const [isLoading, setIsLoading] = useState( false );

        const loadGames = useCallback( () => {
            setIsLoading( true )
            fetchGames()
                .then( (games) => setGames( games ) )
                .finally( () => {
                    setIsLoading( false );
                } )
        }, [] );

        useEffect( () => {
            loadGames();
        }, [] );

        return <Component { ...props } games={ games } isLoading={ isLoading }/>;
    }
}
