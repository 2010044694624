import { Heading, Text } from '@chakra-ui/react';
import { compose } from 'lodash/fp';
import React from 'react';
import { withUserInfo, WithUserInfoProps } from '../../hoc/with-user-info';

export type WelcomeMessageProps = WithUserInfoProps;

export const WelcomeMessage = ({ userInfo }: WelcomeMessageProps) => {
    if (!userInfo) {
        return null;
    }

    return (<Heading as="h1" size="2xl">
        Welcome <Text as="span" color="brand.200">{ userInfo.username }</Text>
    </Heading>)
}

export default compose( withUserInfo )( WelcomeMessage );
