import { Box, Grid, VStack } from '@chakra-ui/react';
import { compose } from 'lodash/fp';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { doLogOut } from '../../auth/api/auth';
import { useBreadcrumbs } from '../../base/hooks/useBreadcrumbs';
import AppLayout, { CONTENT_SIZES } from '../../base/layouts/AppLayout';
import ConnectDeviceForm from '../../device/components/ConnectDeviceForm/ConnectDeviceForm';
import DeviceInfo from '../../device/components/DeviceInfo/DeviceInfo';
import { ChangePassword } from '../components/ChangePassword/ChangePassword';
import { UserProfileCard } from '../components/UserProfileCard/UserProfileCard';
import { withUserInfo, WithUserInfoProps } from '../hoc/with-user-info';

export type  UserProfilePageProps = WithUserInfoProps;

export const UserProfilePage = ({ userInfo, refreshUserInfo }: UserProfilePageProps) => {
    const history = useHistory();
    const [t] = useTranslation('user');
    useBreadcrumbs( [
        { text: t('nav.profile.title'), url: '/' },
        { text: t('nav.self.title'), url: '/users/me' },
    ] );

    useEffect( () => {
        if (!userInfo) {
            history.push( '/' );
        }
    }, [userInfo] )

    const onPasswordChanged = useCallback( () => {
        doLogOut()
            .then( () => {
                history.push( '/' )
            } )
    }, [] );

    return (<AppLayout contentSize={ CONTENT_SIZES.NARROW }>
        <Grid templateColumns="8fr 4fr" gap={ 8 }>
            <VStack spacing={ 6 }
                    align="stretch">
                { userInfo?.hasConnectedDevice ? <DeviceInfo onDisconnected={ refreshUserInfo }/> :
                    <ConnectDeviceForm/> }

                <ChangePassword onPasswordChanged={ onPasswordChanged }/>
            </VStack>

            <Box>
                {userInfo && <UserProfileCard userInfo={ userInfo }/> }
            </Box>
        </Grid>
    </AppLayout>);
}

export default compose( withUserInfo )( UserProfilePage );
