import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { compose } from 'lodash/fp';
import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { doLogOut } from '../../../auth/api/auth';
import { isAuthenticatedState } from '../../../auth/state/is-authenticated';
import { withUserInfo, WithUserInfoProps } from '../../hoc/with-user-info';

export type UserMenuProps = WithUserInfoProps;

export function UserMenu({ userInfo }: UserMenuProps) {
    const history = useHistory();
    const [, setAuthenticated] = useRecoilState( isAuthenticatedState );

    const onLogoutClicked = useCallback( () => {
        doLogOut()
            .then( () => {
                setAuthenticated( false );
                history.push( '/' );
            } )
    }, [] );

    if (!userInfo) {
        return null;
    }

    return (
        <Menu>
            <MenuButton as={ Button } variant="ghost">
                { userInfo.username }
            </MenuButton>
            <MenuList background="gray.600" borderWidth="0">
                <MenuItem
                    as={Link}
                    to='/users/me'
                    _focus={ { color: 'yellow.200' } }>
                    My Profile
                </MenuItem>
                <MenuItem
                    _focus={ { color: 'yellow.200' } }
                    onClick={ onLogoutClicked }>
                    Logout
                </MenuItem>
            </MenuList>
        </Menu>
    );
}

export default compose( withUserInfo )( UserMenu );
