import { CustomRoute } from '../../router/routes';
import UserProfilePage from './pages/UserProfilePage';

export const userRoutes: CustomRoute[] = [
    {
        path: '/users/me',
        exact: true,
        component: UserProfilePage,
        meta: {
            requiresAuth: true,
        },
    },
];
