import React, { ComponentType, useEffect, useState } from 'react';
import { fetchFeaturedGames, GameModel } from '../api/games';

export type WithFeaturedGamesProps = {
    featuredGames: GameModel[];
}

export function withFeaturedGames<P = {}>(Component: ComponentType<P>): React.FC<P> {
    return function withFeaturedGamesContainer(props) {
        const [games, setGames] = useState<GameModel[]>( [] );

        useEffect( () => {
            fetchFeaturedGames()
                .then( (games) => setGames( games ) );
        }, [] );

        return <Component { ...props } featuredGames={ games }/>;
    }
}
