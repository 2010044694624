import { CustomRoute } from '../../router/routes';
import LoginPage from './pages/LoginPage';

export const authRoutes: CustomRoute[] = [
    {
        path: '/auth/login',
        exact: true,
        component: LoginPage,
        meta: {
            requiresAuth: false,
        },
    },
];
