import React, { ComponentType } from 'react';
import AppLayout from '../layouts/AppLayout';

export function withAppLayout<P = {}>(Component: ComponentType<P>): React.FC<P> {
    return function withAppLayoutContainer(props) {
        return <AppLayout>
            <Component { ...props }/>
        </AppLayout>;
    }
}
