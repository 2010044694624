import { CustomRoute } from '../../router/routes';
import DashboardPage from './pages/DashboardPage';

export const dashboardRoutes: CustomRoute[] = [
    {
        path: '/',
        exact: true,
        component: DashboardPage,
        meta: {
            requiresAuth: true,
        },
    },
];
