import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { FullScreenLoader } from '../../base/components/FullScreenLoader';
import { getUserInfo, UserInfoData } from '../api/user';
import { userInfoState } from '../state/user-info-state';

export type WithUserInfoProps = {
    userInfo: UserInfoData | null;
    refreshUserInfo: Function,
}

export function withUserInfo<P = {}>(Component: ComponentType<P>): React.FC<P> {
    return function withUserInfoContainer(props) {
        const [userInfo, setUserInfo] = useRecoilState( userInfoState );
        const [isLoading, setLoading] = useState( userInfo === null );

        const loadData = useCallback( () => {
            setLoading( true );
            getUserInfo()
                .then( (data) => setUserInfo( data ) )
                .catch( () => setUserInfo( null ) )
                .finally( () => {
                    setLoading( false );
                } );
        }, [] );

        const refreshUserInfo = useCallback( () => {
            loadData();
        }, [] );

        useEffect( () => {
            if (!userInfo) {
                loadData();
            }
        }, [] )

        if (isLoading) {
            return <FullScreenLoader/>;
        }

        return <Component key="content" { ...props } userInfo={ userInfo } refreshUserInfo={ refreshUserInfo }/>;
    }
}
