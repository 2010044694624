import { Box, Flex, Text } from '@chakra-ui/react';
import { compose } from 'lodash/fp';
import React from 'react';
import { version } from '../../../../package.json';
import { withBackendInfo, WithBackendInfoProps } from '../hoc/with-backend-info';

type FooterBarProps = WithBackendInfoProps;

const FE_ENV = process.env.REACT_APP_ENVIRONMENT;

export const FooterBar = ({ backendInfo }: FooterBarProps) => {
    return <Flex width="100%" height="120px" justifyContent="center" mt={ 10 } backgroundColor={ 'gray.900' } p={ 4 }>
        <Flex maxWidth={ 1440 } width="100%" justifyContent="space-between">
            <Box>
                <Box>Frontend Env <Text as="span" fontWeight="bold">{ FE_ENV }</Text></Box>
                <Box>Frontend Version <Text as="span" fontWeight="bold">v{ version }</Text></Box>
            </Box>

            <Box>
                <Box>Backend Env <Text as="span" fontWeight="bold">{ backendInfo.environment }</Text></Box>
                <Box>Backend Version <Text as="span" fontWeight="bold">v{ backendInfo.version }</Text></Box>
            </Box>
        </Flex>
    </Flex>
}

export default compose( withBackendInfo )( FooterBar );
