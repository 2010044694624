import { ChakraProvider } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { theme } from './config/theme';
import FooterBar from './modules/base/components/FooterBar';
import { FullScreenLoader } from './modules/base/components/FullScreenLoader';
import PrivateRoute from './modules/base/components/PrivateRoute';
import NotFoundPage from './modules/base/pages/NotFoundPage';
import { routes } from './router/routes';

export const App = () => {
    return (
        <ChakraProvider theme={ theme }>
            <HashRouter>
                <Suspense fallback={ <FullScreenLoader/> }>
                    <Switch>
                        { routes.map( (route, i) => {
                            if (route.meta?.requiresAuth) {
                                return <PrivateRoute path={ route.path }
                                                     exact={ route.exact }
                                                     component={ route.component }
                                                     key={ i }/>;
                            }
                            return <Route path={ route.path }
                                          exact={ route.exact }
                                          component={ route.component }
                                          key={ i }/>;
                        } ) }

                        <Route component={ NotFoundPage }/>
                    </Switch>
                </Suspense>
                <FooterBar/>
            </HashRouter>
        </ChakraProvider>
    )
}

export default App;
