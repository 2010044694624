import { Button, Flex, Heading, Image } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export type EmptyStateProps = {
    title: string;
    link: string;
    linkLabel: string;
    illustration: string;
    cta?: ReactElement
};

export const EmptyState = ({ title, link, linkLabel, illustration, cta }: EmptyStateProps) => {
    return <Flex direction="column" justifyContent="center" alignItems="center" mt={ 10 }>
        <Heading as="h2" mb={ 10 }>{ title }</Heading>
        <Image src={ `/img/illustrations/${ illustration }.svg` }
               height="400px"/>
        { cta ? cta : <Button as={ Link } to={ link } variant={ 'link' } mt={ 6 }>{ linkLabel }</Button> }
    </Flex>;
}
