import { Box, Stack } from '@chakra-ui/react';
import compose from 'lodash/fp/compose'
import React from 'react';
import { withAppLayout } from '../../base/hoc/withAppLayout';
import { useBreadcrumbs } from '../../base/hooks/useBreadcrumbs';
import { GamesTeaser } from '../../games/components/GamesTeaser';
import { withFeaturedGames, WithFeaturedGamesProps } from '../../games/hoc/with-featured-games';
import WelcomeMessage from '../../user/components/WelcomeMessage/WelcomeMessage';

type Props = WithFeaturedGamesProps;

export function DashboardPage({ featuredGames }: Props) {
    useBreadcrumbs( [{
        text: 'Store',
        url: '/',
    }] )

    return <>
        <Box mb={ 24 }>
            <WelcomeMessage/>
        </Box>
        <Stack direction={ 'column' } spacing={ 8 }>
            <GamesTeaser title="Weeklies Special" games={ featuredGames } limit={ 4 }/>
            <GamesTeaser title="Editor Picks" games={ featuredGames } limit={ 4 }/>
            <GamesTeaser title="Top Trending" games={ featuredGames } limit={ 8 }/>
        </Stack>
    </>

}

export default compose( withAppLayout, withFeaturedGames )( DashboardPage );
