import { Box, Grid, Heading } from '@chakra-ui/react';
import React from 'react';
import { GameModel } from '../api/games';
import TeaserGameCard from './TeaserGameCard';

type GamesTeaserProps = {
    games: GameModel[];
    limit: number;
    title: string;
}

export const GamesTeaser = ({ games, limit, title }: GamesTeaserProps) => {
    return (<Box>
        <Heading as="h3" size="sm" mb={ 5 }>{ title }</Heading>
        <Grid templateColumns={ `repeat(${ 4 }, 1fr)` } gap={ 3 }>
            { games.slice( 0, limit ).map( (game) => (
                <TeaserGameCard
                    id={ game.id }
                    visualUrl={ game.primaryVisual }
                    name={ game.name }
                    currency={ game.currency }
                    price={ game.price }
                    description={ game.description }
                    key={ game.id }/>
            ) ) }
        </Grid>

    </Box>)
}

export default GamesTeaser;
