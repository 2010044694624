import { Flex } from '@chakra-ui/react';
import compose from 'lodash/fp/compose'
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { doLogin } from '../api/auth';
import LoginForm from '../components/LoginForm/LoginForm';
import { withAuthCheck, WithAuthCheckProps } from '../hoc/with-auth-check';
import { isAuthenticatedState } from '../state/is-authenticated';

export type LoginPageProps = WithAuthCheckProps;

export function LoginPage({ isAuthenticated }: LoginPageProps) {
    const [isLoading, setLoading] = useState( false );

    const [, setIsAuthenticated] = useRecoilState( isAuthenticatedState );


    const onLogin = (username: string, password: string) => {
        setLoading( true )

        doLogin( username, password )
            .then( (success) => {
                setIsAuthenticated( success );
            } )
            .finally( () => {
                setLoading( false )
            } );
    }

    if (isAuthenticated) {
        return <Redirect to={ '/' }/>;
    }

    return (<Flex w="100vw" h="100vh" align="center" justifyContent="center">
        <LoginForm onLogin={ onLogin } isLoading={ isLoading }/>
    </Flex>);
}

export default compose( withAuthCheck )( LoginPage )
